const entityTypeToEntityIdMap = (request, context) => {
  const principalJwt = request.headers.authorization;

  let expUserId = "";
  try {
    if (principalJwt != null) {
      const baseJwt = principalJwt.replace("EGToken Principal-JWT=", "");
      const splitJwt = baseJwt.split(".");
      if (splitJwt.length > 1) {
        const decodedString = JSON.parse(atob(splitJwt[1]));
        expUserId = decodedString["sub"];
      }
    }
  } catch (error) {
    /*No logging because the bernie ServerLogger can't log from this file*/
  }
  return {
    ExpUserID: expUserId,
  };
};

module.exports = entityTypeToEntityIdMap;
